









































































































































































































































































































































































































































































































.editor .ql-editor
  min-height 200px
.title-error h6
  color var(--q-color-negative)
  margin 0
  text-transform uppercase
