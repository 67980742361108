




















































































































































































.leg-card .q-card-section
  padding-top 0!important
