




































































































.mobilleo-fee .q-if-label
  color white!important
