































































































































.checkbox-vat
  margin-top 10px
.purchase
  border 1px solid #979797
