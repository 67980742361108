







































































































.invoices-title
  display flex
  justify-content space-between

.invoices-title .title
  margin-top 10px

.invoices-wrapper
  max-width calc(100vw - 32px)
  overflow auto

.purchase-invoice
  min-width 900px
  @media (min-width: 768px)
    min-width 800px
