

















































































































































































.leg-card .q-card-main
  padding-top 0!important
