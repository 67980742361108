












































.img-container
  height 200px
.uploaded-img
  cursor pointer
  width 100%
  height 90%
  object-fit cover
.image-name
  overflow hidden
  white-space nowrap
