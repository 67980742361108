































































.content-type
  position relative
  cursor pointer
.content-type.selected i, .content-type.selected h5
  color white!important
.content-type h5
  width 100%
  text-align right

.icon
  font-size 36px!important
.content-type
  .row > div
    display flex
    align-items center

@media screen and (min-width: 1300px)
  .icon
    font-size 60px!important
  .content-type h5
    text-align right
