
























































































.grid
  display grid
  grid-template-columns 50% 50%
  font-weight 400
  align-items baseline
