






































.active
  background aliceblue
strong
  display inline-block
  border-bottom 1px solid black
  margin auto
  margin-bottom 10px
  text-align center
  cursor pointer
