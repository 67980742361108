.booking-form-container {
  height: calc(100vh - 64px);
  overflow: auto;
}
h4 {
  margin-bottom: 20px;
}
.purchase-invoices {
  margin-top: 15px;
}
/*# sourceMappingURL=src/pages/request/request-actions/book/index.css.map */