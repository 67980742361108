.editor .ql-editor {
  min-height: 200px;
}
.editor .ql-container {
  border-left: 0;
  border-right: 0;
}
.request-details-header {
  display: flex;
  justify-content: space-between;
}
.capitalize {
  text-transform: capitalize;
}
.message {
  max-width: 768px;
  margin: auto;
}
.message h5 {
  padding: 0px 15px;
}
.grid {
  display: grid;
  height: 100%;
  grid-template-columns: 1fr 1fr;
}
.email-address {
  word-break: break-all;
}
.key-info {
  border-bottom: 1px #d3d3d3 solid;
}
/*# sourceMappingURL=src/pages/request/view/index.css.map */