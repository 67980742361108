






















































































































































































.content-type
  padding-top 10px
.booking-drawer .q-drawer
  width 30%
h5
  text-transform: capitalize
