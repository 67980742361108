





















































































































































































































.request-flow
  padding 15px
  border-bottom 1px solid lightgrey

.assignee
  padding-bottom 16px
.assignee, .claim
  text-align center

.assignee-name
  font-size 24px
