









































































































































































































































































.field-table thead th
.field-table tbody td
.field-table .q-badge
  font-size 1rem
.field-table .q-badge
  padding: 6px
.confirmation-modal .modal-content
  width 70vw
  max-width 800px!important
.confirmation-modal .modal-body
  height 40vh
  max-height 300px
.dialog .content
  width 20%
  text-transform capitalize
