

































.booking-note .ql-container
  border 0
.booking-note .ql-editor
  min-height 200px
