.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 50%);
  font-weight: 400;
  align-items: baseline;
}
.leg {
  background: #f5f5f5;
  border-radius: 3px;
  margin-bottom: 12px;
}
/*# sourceMappingURL=src/pages/request/request-actions/forms/shared/confirmations/eurostar.css.map */