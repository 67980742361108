.payments-title {
  display: flex;
  justify-content: space-between;
}
.payments-title .title {
  margin-top: 10px;
}
form {
  max-width: 500px;
}
.table {
  margin-top: 8px;
}
.invalid {
  margin-right: 10px;
  line-height: 2.3;
  color: #f00;
}
/*# sourceMappingURL=src/pages/request/view/payments.css.map */