
































































































h5
  text-align center
  margin-top 20px
  font-size 32px
  font-weight 300
  font-style italic

.unavailable-journey-text
  justify-content space-around
  text-align center
  color faded
