


































































































































.grid
  display grid
  grid-template-columns repeat(auto-fill, 50%);
  font-weight 400
  align-items baseline

.leg
  background: whitesmoke
  border-radius 3px
  margin-bottom 12px
