.request-flow {
  padding: 15px;
  border-bottom: 1px solid #d3d3d3;
}
.assignee {
  padding-bottom: 16px;
}
.assignee,
.claim {
  text-align: center;
}
.assignee-name {
  font-size: 24px;
}
/*# sourceMappingURL=src/pages/request/view/flow/index.css.map */