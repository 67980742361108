.active {
  background: #f0f8ff;
}
strong {
  display: inline-block;
  border-bottom: 1px solid #000;
  margin: auto;
  margin-bottom: 10px;
  text-align: center;
  cursor: pointer;
}
/*# sourceMappingURL=src/pages/request/view/flow/flow-item.css.map */