.editor-dialog .q-card__section {
  padding: 0;
}
.editor-dialog .modal-buttons {
  padding: 15px;
}
.editor-dialog .modal-scroll {
  max-height: 500px;
}
.ql-toolbar.ql-snow {
  border-top: 0 !important;
  border-right: 0 !important;
  border-left: 0 !important;
  padding: 1rem 2rem;
}
.editor-dialog .ql-container {
  border-right: 0 !important;
  border-left: 0 !important;
}
.editor-dialog .ql-editor {
  min-height: 150px;
  max-height: 200px;
  overflow: auto;
}
.editor-wrapper {
  min-height: 200px;
}
svg {
  display: block;
  margin: auto;
}
.labelled-btn {
  margin-top: 15px;
}
/*# sourceMappingURL=src/pages/request/view/notes.css.map */