





































































































































































.modal-message
  white-space pre

.invoice-item
  display grid
  grid-template-columns 20% 20% 20% 20% 20%
  font-weight 400
  align-items baseline
